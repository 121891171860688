import React, { useState, useContext, Fragment } from "react";
import history from "../../utils/history";
import { authenticateUser } from "../../services/aws-http-services";
import { AuthContext } from "../../context/index";
import { AUTH_STATUS_CODES } from "../../config/cognito.config";
import { getAutodeskModule, navigateToSelectedModuleDashboard } from "../../common/common-service";
import { setItem } from "../../utils/LocalStorage";
import { TOAST_LIST } from "../../config/toast.config";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Forms from "../../components/Forms";
import Toast from "../../components/Toast";

const FormChildren = ({
    error,
    loading,
    formValue,
    showPwd,
    showPwdhandler,
    loginClickHandler,
    onChangeHandler,
}) => {
    return (
        <Fragment>
            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                    name="email"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    type="email"
                    value={formValue.email}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Enter Email"
                />
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="relative">
                    <input
                        name="password"
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        value={formValue.password}
                        type={showPwd ? "text" : "password"}
                        onChange={(e) => onChangeHandler(e)}
                        placeholder="Enter Password"
                    />
                    {formValue.password !== "" ? (
                        <i
                            className={`far toggle-show-password ${
                                showPwd ? "fa-eye-slash" : "fa-eye"
                            }`}
                            onClick={(e) => showPwdhandler(e)}
                        ></i>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="quick-action">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <Button
                            text="&nbsp;Login"
                            className="btn btn-primary btn-sm"
                            disabled={loading}
                            onClick={(e) => loginClickHandler(e)}
                            children={
                                loading ? <i className="rotating fas fa-circle-notch"></i> : ""
                            }
                        />
                    </div>
                    <div className="forgot-pwd">
                        <Link to="forgot-password">Forgot Password?</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const Login = () => {
    const loginData = {};
    const [errorMsg, setErrMsg] = useState("");
    const [error, showError] = useState(false);
    const [success, showSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [formValue, setFormValue] = useState({ email: "", password: "" });
    const [toastList, setToastList] = useState([]);

    const { setAuthStatus } = useContext(AuthContext);

    const _handleLogin = async (e) => {
        e.preventDefault();
        const { email, password } = formValue;
        if (email === "" || password === "") {
            showError(true);
            setErrMsg("Please enter valid input");
            setLoading(false);
        } else {
            setLoading(true);
            loginData.username = email;
            loginData.password = password;
            loginData.additionalData = {
                name: email,
            };
            try {
                setItem("username", email);
                const authenticate = await authenticateUser({ email, password }, setAuthStatus);
                if (authenticate === AUTH_STATUS_CODES.newPasswordRequired) {
                    // console.log("New pwd required")
                    history.push("/login/change-password");
                } else if (authenticate === AUTH_STATUS_CODES.signedIn) {
                    // console.log("success");
                    navigateToSelectedModuleDashboard();
                } else if (authenticate === AUTH_STATUS_CODES.noSuchUser) {
                    // console.log("No such user")
                    setToastList([
                        {
                            ...TOAST_LIST.danger,
                            title: `User does not belongs to ${getAutodeskModule()}`,
                        },
                    ]);
                }
                setLoading(false);
            } catch (e) {
                showError(true);
                setErrMsg(e.message);
                setLoading(false);
            }
        }
    };

    const _handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
    };

    return (
        <Fragment>
            <Header />
            <Forms
                text="Login"
                description=""
                error={error}
                errorMsg={errorMsg}
                success={success}
                succesMsg=""
            >
                <FormChildren
                    error={error}
                    loading={loading}
                    formValue={formValue}
                    showPwd={showPwd}
                    showPwdhandler={(e) => setShowPwd(!showPwd)}
                    loginClickHandler={(e) => _handleLogin(e)}
                    onChangeHandler={(e) => _handleOnChange(e)}
                />
            </Forms>
            <Toast
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                dismissTime={parseInt(3000, 10)}
            />
        </Fragment>
    );
};

export default Login;
