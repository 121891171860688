import moment from "moment";
import { AddUserActivity } from "../services/aws-http-services";
import { getAutodeskModule } from "../common/common-service";

export function ConvertToCSV(objArray, headerList) {
    let str = "";
    let row = "";

    // tslint:disable-next-line:forin
    for (let index in headerList) {
        row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    Object.keys(objArray).forEach((dates) => {
        let line = moment(Number(dates)).format("MM/DD/YYYY") + " ";
        const forecastObject = objArray[dates];
        Object.keys(forecastObject).forEach((items) => {
            line += "," + forecastObject[items];
        });
        str += line + "\r\n";
    });
    return str;
}

export function ConvertToCSVMultiple(ObjectArr1, ObjectArr2, ObjectArr3, HeaderList1, HeaderList2) {
    let str = "";
    let row = "";

    // tslint:disable-next-line:forin
    for (let index in HeaderList1) {
        row += HeaderList1[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    Object.keys(ObjectArr1).forEach((data) => {
        let line = "";
        const item = ObjectArr1[data];
        Object.keys(item).forEach((values) => {
            if (values == "ds") {
                line += moment(Number(item[values])).format("MM/DD/YYYY") + ",";
            } else {
                line += item[values] + ",";
            }
        });
        line = line.slice(0, -1);
        str += line + "\r\n";
    });

    str += "\r\n\r\n";
    row = "";

    // tslint:disable-next-line:forin
    for (let index in HeaderList2) {
        row += HeaderList2[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";

    Object.keys(ObjectArr3).forEach((value, initialIndex) => {
        let line = "";
        const item = ObjectArr3[value];
        if (ObjectArr2[initialIndex] != null) {
            const actual = ObjectArr2[initialIndex];
            Object.values(actual).forEach((data, index) => {
                if (index == 2) {
                    line += data + ",";
                } else {
                    line += moment(Number(data)).format("MM/DD/YYYY") + ",";
                }
            });
            Object.values(item).forEach((data, index) => {
                if (index == 1) {
                    line += data + ",";
                }
            });
            line = line.slice(0, -1);
            str += line + "\r\n";
        } else {
            line += "," + "," + ",";
            Object.values(item).forEach((data, index) => {
                if (index == 1) {
                    line += data + ",";
                } else {
                    line += moment(Number(data)).format("MM/DD/YYYY") + ",";
                }
            });
            line = line.slice(0, -1);
            str += line + "\r\n";
        }
    });

    return str;
}

export function objectToQueryString(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
}

export function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
}

export function formatBytes(a, b = 2) {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));
    return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    );
}

export function findMinMax(arr, attribute, type, dateFormat = "MM/DD/YYYY") {
    if (type === "date" || type === "Date") {
        let min = moment(arr[0][attribute], dateFormat).valueOf(),
            max = moment(arr[0][attribute], dateFormat).valueOf();
        for (let i = 1, len = arr.length; i < len; i++) {
            let v = moment(arr[i][attribute], dateFormat).valueOf();
            min = v < min ? v : min;
            max = v > max ? v : max;
        }
        return [min, max];
        ``;
    } else {
        let min = arr[0][attribute],
            max = arr[0][attribute];
        for (let i = 1, len = arr.length; i < len; i++) {
            let v = arr[i][attribute];
            if (v != "nan") {
                min = v < min ? v : min;
                max = v > max ? v : max;
            }
        }
        return [min, max];
    }
}

export function dateToLocal(date) {
    const local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
}

export const qtrConvertor = function (date, i) {
    const thisYear = date.getFullYear();
    // const newDate = date.setMonth(date.getMonth() + 1);
    const month = date.getMonth() + 1;
    // console.log(date, date.getMonth(), month, date.getFullYear());

    let quarters = `${thisYear} Q4`;
    if (month >= 2 && month <= 4) {
        quarters = `${thisYear} Q1`;
    } else if (month >= 5 && month <= 7) {
        quarters = `${thisYear} Q2`;
    } else if (month >= 8 && month <= 10) {
        quarters = `${thisYear} Q3`;
    } else if (month >= 11 || month == 1) {
        quarters = `${thisYear} Q4`;
    }

    return quarters;
};

export const htmlTblToExcel = (table, sheetName, fileName) => {
    const fnExcelReport = (table, fileName) => {
        let tab_text = "<table border='2px'>";

        if (!table.nodeType) table = document.getElementById(table);

        $("tbody > tr[data-level='0']").show();
        tab_text = tab_text + table.innerHTML;

        tab_text = tab_text + "</table>";
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
        tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand("SaveAs", false, fileName + ".xls");
        $("tbody > tr[data-level='0']").hide();
        return sa;
    };

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        return fnExcelReport(table, fileName);
    }

    const uri = "data:application/vnd.ms-excel;base64,",
        templateData =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
        base64Conversion = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
        },
        formatExcelData = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
        };

    $("tbody > tr[data-level='0']").show();

    if (!table.nodeType) table = document.getElementById(table);

    const ctx = { worksheet: sheetName || "Worksheet", table: table.innerHTML };

    const element = document.createElement("a");
    element.setAttribute("href", uri + base64Conversion(formatExcelData(templateData, ctx)));
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    $("tbody > tr[data-level='0']").hide();
};

export function splitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
}

export function transposeTable(divElement, existingTblElement, newTblElement) {
    let maxColumns = 0;

    for (let r = 0; r < existingTblElement.rows.length; r++) {
        if (existingTblElement.rows[r].cells.length > maxColumns) {
            maxColumns = existingTblElement.rows[r].cells.length;
        }
    }
    for (let c = 0; c < maxColumns; c++) {
        newTblElement.insertRow(c);
        for (let r = 0; r < existingTblElement.rows.length; r++) {
            if (existingTblElement.rows[r].length <= c) {
                newTblElement.rows[c].insertCell(r);
                newTblElement.rows[c].cells[r] = "-";
            } else {
                newTblElement.rows[c].insertCell(r);
                newTblElement.rows[c].cells[r].innerHTML =
                    existingTblElement.rows[r].cells[c].innerHTML;
            }
        }
    }
    divElement.appendChild(newTblElement);
}

export const mergeArrayOfObjects = (arr1, arr2, attr) => {
    // 1. create a map
    const map = new Map();

    // 2. concat array
    // arr1.concat(arr2) === [...arr1, ...arr2]
    const arr3 = [...arr1, ...arr2];

    // 3. for ... of, iterator array
    for (const obj of arr3) {
        if (!map.has(obj[attr])) {
            // add
            map.set(obj[attr], obj);
        } else {
            // update
            map.set(obj[attr], {
                ...map.get(obj[attr]),
                ...obj,
            });
        }
    }

    // 4. get new merged unqiue array
    return [...map.values()];
};

export function tableToExcel(tables, sheetName, filename) {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
        "<head>" +
        "<!--[if gte mso 9]>" +
        "<xml>" +
        "<x:ExcelWorkbook>" +
        "<x:ExcelWorksheets>" +
        "<x:ExcelWorksheet>" +
        "<x:Name>{worksheet}</x:Name>" +
        "<x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>" +
        "</x:ExcelWorksheet>" +
        "</x:ExcelWorksheets>" +
        "</x:ExcelWorkbook>" +
        "</xml>" +
        "<![endif]-->" +
        '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>' +
        "</head>" +
        "<body><table>{table}</table></body>" +
        "</html>";

    const base64Encode = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
    };

    const format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        });
    };

    const completeTables = [];

    // Concatenate HTML content of all tables
    tables.forEach((tableId) => {
        const table = document.getElementById(tableId);
        completeTables.push(table.innerHTML);
    });

    const ctx = { worksheet: sheetName[0] || "Worksheet", table: completeTables.join("<br>") };

    // Generate Excel file
    const element = document.createElement("a");
    element.setAttribute("href", uri + base64Encode(format(template, ctx)));
    element.setAttribute("download", filename + ".xls");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function enumerateDaysBetweenDates(startDate, endDate, attribute) {
    let date = [];
    while (moment(startDate) <= moment(endDate)) {
        date.push(moment(startDate).toDate());
        startDate = moment(startDate).add(1, attribute).toDate();
    }
    return date;
}

export const isJson = (str) => {
    try {
        const converted = JSON.parse(str);
        return typeof converted === "object" ? true : false;
    } catch (e) {
        return false;
    }
    return true;
};

export const flatten = (obj, final = {}) => {
    for (let key in obj) {
        if (typeof obj[key] === "string" && isJson(obj[key])) {
            flatten(JSON.parse(obj[key]), final);
        } else {
            final[key] = String(obj[key]);
        }
    }
    return final;
};

export const objToArrayOfObj = (obj) => {
    return Object.keys(obj).map((e) => {
        const ret = {};
        ret[e] = obj[e];
        return ret;
    });
};

export const htmlTablesToExcel = (tables, sheetNames, fileName) => {
    const fnExcelReport = (tables, sheetNames, fileName) => {
        const uri = "data:application/vnd.ms-excel;base64,";
        const templateData = `
      <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
        <head>
          <!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>{worksheets}</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
          <meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
        </head>
        <body>{tables}</body>
      </html>
    `;
        const worksheetTemplate = `
      <x:ExcelWorksheet>
        <x:Name>{sheetName}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
        <table>{table}</table>
      </x:ExcelWorksheet>
    `;

        const base64Conversion = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
        };

        const formatExcelData = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
        };

        const worksheets = [];
        const tablesHTML = [];

        for (let i = 0; i < tables.length; i++) {
            const table = tables[i];
            const sheetName = sheetNames[i];
            let tableHTML = "<table border='2px'>";

            if (!table.nodeType) {
                table = document.getElementById(table);
            }

            $("tbody > tr[data-level='0']").show();
            tableHTML += table.innerHTML;
            tableHTML += "</table>";

            tableHTML = tableHTML.replace(/<A[^>]*>|<\/A>/g, ""); // remove links
            tableHTML = tableHTML.replace(/<img[^>]*>/gi, ""); // remove images
            tableHTML = tableHTML.replace(/<input[^>]*>|<\/input>/gi, ""); // remove input params

            $("tbody > tr[data-level='0']").hide();

            tablesHTML.push(tableHTML);
            worksheets.push(formatExcelData(worksheetTemplate, { sheetName, table: tableHTML }));
        }

        const ctx = { worksheets: worksheets.join(""), tables: tablesHTML.join("") };

        const element = document.createElement("a");
        element.setAttribute("href", uri + base64Conversion(formatExcelData(templateData, ctx)));
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        return fnExcelReport(tables, sheetNames, fileName);
    }

    fnExcelReport(tables, sheetNames, fileName);
};

// export const copyImageSrcToClipboard = (imageSrc) => {
//     const textarea = document.createElement("textarea");
//     textarea.value = imageSrc;
//     document.body.appendChild(textarea);
//     textarea.select();
//     document.execCommand("copy");
//     document.body.removeChild(textarea);
// };

// export const captureScreenshot = (element) => {
//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");
//     canvas.width = element.offsetWidth;
//     canvas.height = element.offsetHeight;

//     const image = new Image();

//     const onImageLoad = () => {
//         context.drawImage(
//             image,
//             0,
//             0,
//             element.offsetWidth,
//             element.offsetHeight,
//             0,
//             0,
//             element.offsetWidth,
//             element.offsetHeight,
//         );

//         const imgData = canvas.toDataURL("image/png");
//         copyImageSrcToClipboard(imgData);
//     };

//     image.addEventListener("load", onImageLoad);
//     image.src = element.src;
//     console.log(image.src);
// };

export function getTextWidth(text, fontSize = "0.7em", fontFamily = "sans-serif") {
    const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const tempText = document.createElementNS("http://www.w3.org/2000/svg", "text");

    tempText.style.fontSize = fontSize;
    tempText.style.fontFamily = fontFamily;
    tempText.textContent = text;

    tempSvg.appendChild(tempText);

    document.body.appendChild(tempSvg);

    const width = tempText.getBBox().width;

    document.body.removeChild(tempSvg);

    return width;
}

export const sendActivityDetails = (event_type, activity_type, activity_details) => {
    try {
        const ad_module = getAutodeskModule();
        const userActivity = {
            app_name: ad_module,
            event_type,
            activity_type,
            activity_details,
        };

        AddUserActivity(userActivity);
    } catch (err) {
        console.log(err);
    }
};
