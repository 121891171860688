import Amplify from '@aws-amplify/core';
import { cognitoConfig } from "./cognito.config";

const API_GATEWAY_URL = `https://${cognitoConfig.apiGateway}`;
const AUTO_COMP_API_GATEWAY_URL = `https://${cognitoConfig.apiGatewayAutoComp}`;

const endPointRegion = {
    endpoint: API_GATEWAY_URL,
    region: cognitoConfig.userPool.region
}

const autoCompEndPointRegion = {
    endpoint: AUTO_COMP_API_GATEWAY_URL,
    region: cognitoConfig.userPool.region
}

Amplify.configure({
    ssr: true,
	Auth: {
		mandatorySignIn: false,
		region: cognitoConfig.userPool.region,
		userPoolId: cognitoConfig.userPool.UserPoolId,
		identityPoolId: cognitoConfig.identityPoolId,
        userPoolWebClientId: cognitoConfig.userPool.ClientId,
        oauth: {
            domain: cognitoConfig.oAuthDomain,
            // scope: [
            //     "email",
            //     "openid",
            //     "aws.cognito.signin.user.admin"
            // ],
            redirectSignIn: cognitoConfig.oAuthRedirectSignIn,
            redirectSignOut: cognitoConfig.oAuthRedirectSignOut,
            responseType: cognitoConfig.oAuthRespType,
            client_id: cognitoConfig.userPool.ClientId
        },
        federationTarget: "COGNITO_USER_POOLS",
    },
    API: {
        endpoints: [
            {
                name: "getGoldenRows",
                ...endPointRegion
            },
            {
                name: "getStatistics",
                ...endPointRegion
            },
            {
                name: "getPriceListingSearch",
                ...endPointRegion
            },
            {
                name: "getDropDownConfig",
                ...endPointRegion
            },
            {
                name: "getProcessedDates",
                ...endPointRegion
            },
            {
                name: "getAutoCompSearch",
                ...autoCompEndPointRegion
            },
            {
                name: "getRecentSearch",
                ...autoCompEndPointRegion
            },
            {
                name: "getTopSearch",
                ...autoCompEndPointRegion
            },
            {
                name: "addRecentSearch",
                ...autoCompEndPointRegion
            },
            {
                name: "exportReport",
                ...endPointRegion
            },
            {
                name: "getExportReportUrl",
                ...endPointRegion
            },
            {
                name: "getOWSAlertDetails",
                ...endPointRegion
            },
            {
                name: "searchWatchDogDetails",
                ...endPointRegion
            },
            {
                name: "getRecentSearchWatchDog",
                ...endPointRegion
            },
            {
                name: "getIndividualRecentSearchWatchDog",
                ...endPointRegion
            },
            {
                name: "getWatchDogReportUrls",
                ...endPointRegion
            },
            {
                name: "getWatchDogSearchStatus",
                ...endPointRegion
            },
            {
                name: "searchWatchDogRecentSearch",
                ...endPointRegion
            },
            {
                name: "getOWSAlertData",
                ...endPointRegion
            },
            {
                name: "exportOWSAlertData",
                ...endPointRegion
            },
            {
                name: "getSalesFilters",
                ...endPointRegion
            },
            {
                name: "getSalesForecast",
                ...endPointRegion
            },
            {
                name: "getExpenseForecast",
                ...endPointRegion
            },
        ]
    }
});