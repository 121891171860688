import React, { Fragment } from "react";
import "./page-loader.scss"

const PageLoader = ({ text }) => {
    return (
        <Fragment>
          <div id="loader">
            <div className="application-loading-container">
              <div className="application-loading-box">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div className="info-text relative-center">
            {text}
          </div>
        </Fragment>    
    );
  }

export default PageLoader;