import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { removeItem } from "../../utils/LocalStorage";

import "./toast.scss";

const Toast = memo(({ toastList, position, autoDelete, dismissTime, btnRequired, btnCallback }) => {
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
        const interval = setInterval(() => {
            if (autoDelete && toastList.length) {
                deleteToast(toastList[0].id);
            }
        }, dismissTime);

        return () => {
            clearInterval(interval);
        };
    }, [toastList]);

    const deleteToast = (id, btnClick) => {
        /** Do not delete module name if other pages */
        if (window.location.pathname === "/") {
            if (!btnClick) removeItem("ad_module");
        }
        const listItemIndex = list.findIndex((e) => e.id === id);
        const toastListItem = toastList.findIndex((e) => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    };

    const handleRequestAccess = (id) => {
        deleteToast(id, true);
        btnCallback();
    };

    return (
        <>
            <div className={`notification-container ${position}`}>
                {list.map((toast, i) => (
                    <div
                        key={i}
                        className={`notification toast ${position}`}
                        style={{ backgroundColor: toast.backgroundColor }}
                    >
                        <Button
                            onClick={() => deleteToast(toast.id)}
                            children={<i className="fa fa-times" aria-hidden="true"></i>}
                        />
                        {/* <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button> */}
                        <div className="notification-image">
                            <img src={toast.icon} alt="" />
                        </div>
                        <div>
                            <p className="notification-title">{toast.title}</p>
                            {/* <p className="notification-message">
                                    {toast.description}
                                </p> */}
                        </div>
                        {btnRequired ? (
                            <Button
                                text="Request Access"
                                className="btn btn-primary request-access"
                                onClick={() => handleRequestAccess(toast.id)}
                            />
                        ) : null}
                    </div>
                ))}
            </div>
        </>
    );
});

Toast.defaultProps = {
    position: "top-right",
    autoDelete: true,
    dismissTime: 3000,
    btnRequired: false,
};

Toast.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    dismissTime: PropTypes.number,
    btnRequired: PropTypes.bool,
    btnCallback: PropTypes.func,
};

export default Toast;
