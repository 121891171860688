import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./custom-modal.scss";

const CustomModal = ({ modalName, modalSize, modalCustomClass, animation, children, params }) => {
    return (
        <Fragment>
            <div
                className={`modal ${animation}`}
                id={modalName}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="customModalLabel"
                aria-hidden="true"
                {...params}
            >
                <div className={`modal-dialog ${modalSize ? modalSize : ""}`} role="document">
                    <div className={`modal-content ${modalCustomClass ? modalCustomClass : ""}`}>
                        {children}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

CustomModal.defaultProps = {
    modalName: "customModal",
    modalSize: "modal-sm",
    modalCustomClass: "modal",
    animation: "fade-scale",
};

CustomModal.propTypes = {
    modalName: PropTypes.string,
    modalSize: PropTypes.string,
    modalCustomClass: PropTypes.string,
    animation: PropTypes.string,
    children: PropTypes.node,
};

export default CustomModal;
