import React, { memo } from "react";
import PropTypes from "prop-types";
import { getAutodeskModule } from "../../common/common-service";
import { Link } from "react-router-dom";

const Forms = memo(({ 
        text, 
        description, 
        error, 
        errorMsg, 
        success, 
        succesMsg, 
        children 
    }) => {
    return (
        <div className="bg">
            <div className="login-container">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center h-88">
                <div className="col-lg-4 col-md-4 col-xl-4"></div>
                <div className="col-lg-4 col-md-4 col-xl-4 mid-center">
                    <div  className="l-grid-container min-width">
                    <div className="auth-module">
                        <div className="module-title">
                        <h5>{text}</h5>
                        <p>{description}</p>
                        </div>
                        {error ? 
                            <div className="invalid-message">
                            <p>{errorMsg}</p>
                        </div> : ""}
                        {success ? <div className="valid-message">
                            <p>{succesMsg}</p>
                        </div> : ""}
                        <form>
                            {children}
                        </form>
                    </div>
                    <p className="module-info">You are logging into {getAutodeskModule()}.
                        <Link to="/">Click here </Link>to change
                    </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xl-4"></div>
                </div>
            </div>
            </div>
            </div>
    )
});

Forms.propTypes = {
    text: PropTypes.string, 
    description: PropTypes.string, 
    error: PropTypes.bool, 
    errorMsg: PropTypes.string, 
    success: PropTypes.bool, 
    succesMsg: PropTypes.string, 
    children : PropTypes.node
}
export default Forms;