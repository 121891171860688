import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import configStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";

import "./config/aws.amplify.config";

const store = configStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("app"),
);

serviceWorker.unregister();
