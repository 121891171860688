/** Default user auth object */
export const DEFAULT_USER_AUTH = {
    userId: 0,
    idToken: "",
    username: "",
    autodesk_access_token: "",
    autodesk_exp: 0,
    authenticated: false,
};

export const maxFileSize = 1024 * 1024 * 500;
