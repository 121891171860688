import React, { useContext, memo } from "react";
import "./header.scss";
import PropTypes from "prop-types";
import AuthHeader from "../AuthHeader";
import Button from "../Button";
import { AuthContext } from "../../context";

const areEqual = (prevProps, nextProps) => {
    return prevProps.children === nextProps.children;
};

const Header = memo(({ children, brandName }) => {
    const { auth } = useContext(AuthContext);

    return (
        <div className="header">
            {/* {console.log("rendering header")} */}
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand">
                    <img src="assets/img/autodesk-icon-black.png" alt="Logo" />
                    <h4 className="brand-name">{brandName ? brandName : "Autodesk"}</h4>
                </a>
                <Button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    children={<span className="navbar-toggler-icon"></span>}
                />
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button> */}
                <div
                    className="collapse navbar-collapse justify-content-end d-flex"
                    id="navbarSupportedContent"
                >
                    {auth && auth["authenticated"] && auth["autodesk_access_token"] ? (
                        <AuthHeader username={auth["username"].split(".")[0]}>
                            {children}
                        </AuthHeader>
                    ) : null}
                </div>
            </nav>
        </div>
    );
}, areEqual);

Header.propTypes = {
    children: PropTypes.node,
};

export default Header;
