import { environment } from '../../environment';

export const AUTH_STATUS_CODES = {
    success: 'success',
    signedIn: "USER_SRP_AUTH",
    signedOut: 'signedOut',
    incompletedSigninData: 'incompletedSigninData',
    newPasswordRequired: "NEW_PASSWORD_REQUIRED",
    verificationCodeRequired: 'verificationCodeRequired',
    passwordChanged: 'passwordChanged',
    noSuchUser: 'noSuchUser',
    unknownError: 'unknownError'
};

export const cognitoConfig = {
    userPool: {
        UserPoolId: environment.userPoolId,
        ClientId: environment.clientId,
        region: environment.region
    },
    identityPoolId: environment.identityPoolId,
    apiGateway: environment.apiGatewayHost,
    apiGatewayAutoComp: environment.apiGatewayHostAutocomplete,
    oAuthDomain: environment.oAuthConfig.domain,
    oAuthRedirectSignIn: environment.oAuthConfig.redirectSignIn,
    oAuthRedirectSignOut: environment.oAuthConfig.redirectSignOut,
    oAuthIDP: environment.oAuthConfig.identityProvider,
    oAuthRespType: environment.oAuthConfig.responseType,
    ssoUrl: `https://${environment.oAuthConfig.domain}/oauth2/authorize?identity_provider=${environment.oAuthConfig.identityProvider}&redirect_uri=${environment.oAuthConfig.redirectSignIn}&response_type=${environment.oAuthConfig.responseType}&client_id=${environment.clientId}`,
    envPath: environment.envBasePath
};
