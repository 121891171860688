import React, { useEffect, Fragment, useState, useContext } from "react";
import "./App.scss";
import { Router } from "react-router-dom";
import history from "./utils/history";
import { AppRouter } from "./AppRouter";
import { AuthContextProvider } from "./context";
import { BrowserTitleContext, BrowserTitleContextProvider } from "./context/browserTitle";
import { getStoredUserAuth } from "./utils/LocalStorage";
import { useAuthHandler } from "./custom-hooks/AuthHandler";
import { useBrowserTitle } from "./custom-hooks/BrowserTitle";
import { navigateToSelectedModuleDashboard, getAutodeskModule } from "./common/common-service";
import { authenticateUserSSO, signOut } from "./services/aws-http-services";
import { cognitoConfig } from "./config/cognito.config";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { setAuthStatus } = useAuthHandler(getStoredUserAuth());
    const { browserTitle } = useContext(BrowserTitleContext);

    const getuserinfo = async () => {
        // call a promise to waith for currentAuthenticatedUser() to return
        try {
            if (
                window.location.pathname == "/about-us" ||
                window.location.pathname == "/demo-dashboards"
            ) {
                setIsLoading(false);
                history.push(window.location.pathname);
                return;
            }
            const user = await authenticateUserSSO(setAuthStatus);
            if (user && user.auth) {
                // console.log(user);
                setIsLoading(false);
                navigateToSelectedModuleDashboard();
                // if(window.location.pathname !== "/"){
                //   navigateToSelectedModuleDashboard();
                // }
            } else {
                console.log("not signed in");
                window.location.assign(cognitoConfig.ssoUrl);
            }
            // if (window.location.pathname !== "/about-us") {
            //     const user = await authenticateUserSSO(setAuthStatus);
            //     if (user && user.auth) {
            //         // console.log(user);
            //         setIsLoading(false);
            //         navigateToSelectedModuleDashboard();
            //         // if(window.location.pathname !== "/"){
            //         //   navigateToSelectedModuleDashboard();
            //         // }
            //     } else {
            //         console.log("not signed in");
            //         window.location.assign(cognitoConfig.ssoUrl);
            //     }
            // } else {
            //     setIsLoading(false);
            //     // history.push("/about-us");
            // }
        } catch (err) {
            // if(err.includes("not auth")){
            //   window.location.assign(cognitoConfig.ssoUrl);
            // }
            console.log("error in root page", err);
        }
    };

    // const activityPolling = (() => {
    //   const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    //   const pathNames = ["/login", "/forgot-password", "/change-password", "/login/change-password", "/forgot-password/change-password"]
    //   let active = true;
    //   let timeout;

    //   const poll = async () => {
    //     console.log('before polling the server...', active , auth.authenticated)
    //     if(active) {
    //       console.log('polling the server...', window.location.pathname)
    //       const dt = new Date();
    //       dt.setMinutes(dt.getMinutes() + 59);
    //       const authAgain = getStoredUserAuth();
    //       console.log("Executing", Math.floor(dt.getTime()/1000), Math.floor(Date.now() / 1000), authAgain.autodesk_exp, (authAgain.autodesk_exp === undefined || Math.floor(dt.getTime() / 1000) >= authAgain.autodesk_exp) && !pathNames.includes(window.location.pathname));
    //       if((authAgain.autodesk_exp === undefined || Math.floor(dt.getTime() / 1000) >= authAgain.autodesk_exp) && !pathNames.includes(window.location.pathname)){
    //         signOut()
    //         setUnauthStatus();
    //         history.push("/");
    //       }
    //     }
    //   }

    //   const setIdle = () => {
    //     active = false;
    //   }

    //   const setActive = () => {
    //     active = true;
    //     if(timeout)
    //       clearTimeout(timeout);
    //     timeout = setTimeout(setIdle, 10 * 1000);
    //   }

    //   const destroy = () => {
    //     clearInterval(interval);

    //     events.map(event => {
    //       window.removeEventListener(event, setActive);
    //     });

    //     // return;
    //   }

    //   events.map(event => {
    //     window.addEventListener(event, setActive);
    //   });

    //   setActive();

    //   const interval = setInterval(poll, 10 * 1000);

    //   return {
    //     interval: interval,
    //     destroy: destroy
    //   }
    // })();

    // if(auth.authenticated){
    //   setInterval(() => {
    //     const dt = new Date();
    //     dt.setMinutes(dt.getMinutes() + 58);
    //     console.log("Executing", Math.floor(dt.getTime()/1000), Math.floor(Date.now() / 1000) , auth.autodesk_exp);
    //     if(Math.floor(dt.getTime() / 1000) >= auth.autodesk_exp){
    //       <Toast
    //           toastList={[{...TOAST_LIST.info, title: `Session Expired`}]}
    //           position={"top-right"}
    //           autoDelete={true}
    //           dismissTime={parseInt(3000, 10)}
    //       />
    //       setUnauthStatus();
    //       setTimeout(() => history.push("/"), 10000);
    //     }
    //   }, 10000)
    // }

    useEffect(() => {
        getuserinfo();
        document.title = browserTitle;
    }, []);
    return (
        <Fragment>
            {isLoading ? (
                <div id="loader">
                    <div className="application-loading-container">
                        <div className="application-loading-box">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Router history={history}>
                    <AuthContextProvider>
                        <BrowserTitleContextProvider>
                            <AppRouter />
                        </BrowserTitleContextProvider>
                    </AuthContextProvider>
                </Router>
            )}
        </Fragment>
    );
};

export default App;
