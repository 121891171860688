import React, { useState } from "react";

export const useGlobalStateHandler = () => {
    const [tableInputValues, setInputTableValues] = useState({});
    const [dropdownConfigValues, setDropdownConfigValues] = useState([]);
    const [checkupFilterValues, setCheckupFilterValues] = useState({});
    const [fpClearFiles, setFPClearFiles] = useState(false);

    const setGlobalValues = (globalValue) => {
        setInputTableValues(globalValue);
    };

    const setGlobalDropdownValues = (globalValue) => {
        setDropdownConfigValues(globalValue);
    };

    const setGlobalCheckupFilterValues = (globalValue) => {
        setCheckupFilterValues(globalValue);
    };

    const setForecastPlatformClearFiles = (value) => {
        setFPClearFiles(value);
        setTimeout(() => {
            setFPClearFiles(!value);
        }, 1000);
    };

    return {
        tableInputValues,
        setGlobalValues,
        dropdownConfigValues,
        setGlobalDropdownValues,
        checkupFilterValues,
        setGlobalCheckupFilterValues,
        fpClearFiles,
        setForecastPlatformClearFiles,
    };
};
