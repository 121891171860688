export const TOAST_LIST = {
    success: {
        id : 1,
        title: 'Success',
        backgroundColor: '#5cb85c',
        icon: "assets/svg/check.svg"
        
    },
    danger: {
        id : 2,
        title: 'Error',
        backgroundColor: '#dc3545',
        icon: "assets/svg/error.svg"
    },
    info : {
        id : 3,
        title: 'Info',
        backgroundColor: '#5bc0de',
        icon: "assets/svg/info.svg"
    },
    warning : {
        id : 4,
        title: 'Warning',
        backgroundColor: '#f0ad4e',
        icon: "assets/svg/warning.svg"
    }
}
