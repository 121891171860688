import React, { useState } from "react";
/** Utils */
import { setUserAuth, clear } from "../utils/LocalStorage";
import { DEFAULT_USER_AUTH } from "../utils/const";

export const useAuthHandler = (initialState) => {
    const [auth, setAuth] = useState(initialState);
    setUserAuth(initialState);
    const setAuthStatus = (userAuth) => {
        setUserAuth(userAuth);
        setAuth(userAuth);
    };
    const setUnauthStatus = () => {
        clear();
        setUserAuth(DEFAULT_USER_AUTH);
    };
    return {
        auth,
        setAuthStatus,
        setUnauthStatus,
    };
};
