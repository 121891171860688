import React, { useState, createContext } from "react";
import { getStoredUserAuth } from "../utils/LocalStorage";
import { DEFAULT_USER_AUTH } from "../utils/const";
import { useAuthHandler } from "../custom-hooks/AuthHandler";
import { useGlobalStateHandler } from "../custom-hooks/globalStateHandler";

const initialFPData = {
    model_training: {
        statistical_predictor: {
            loading: true,
            data: {},
            error_msg: "",
        },
        machine_learning_predictor: {
            loading: true,
            data: {},
            error_msg: "",
        },
        neural_network: {
            loading: true,
            data: {},
            error_msg: "",
        },
    },
    forecast: {
        loading: true,
        data: {},
        error_msg: "",
    },
};

export const AuthContext = createContext({
    auth: DEFAULT_USER_AUTH,
    setAuthStatus: () => {},
    setUnauthStatus: () => {},
    tableInputValues: {},
    setTableInputValues: () => {},
    dropdownConfigValues: [],
    setDropdownConfigValues: () => [],
    checkupFilterValues: {},
    setCheckupFilterValues: () => {},
    fpClearFiles: false,
    setForecastPlatformClearFiles: () => {},
    fpCompData: initialFPData,
    setFPCompData: () => {},
    fpErrMsg: "",
    setFPErrMsg: () => {},
    fpForecastDsbl: true,
    setFPForecastDsbl: () => {},
});

export const AuthContextProvider = ({ children }) => {
    const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(getStoredUserAuth());
    const [fpCompData, setFPCompData] = useState(initialFPData);
    const [fpErrMsg, setFPErrMsg] = useState("");
    const [fpForecastDsbl, setFPForecastDsbl] = useState(true);

    const {
        tableInputValues,
        setGlobalValues,
        dropdownConfigValues,
        setGlobalDropdownValues,
        checkupFilterValues,
        setGlobalCheckupFilterValues,
        fpClearFiles,
        setForecastPlatformClearFiles,
    } = useGlobalStateHandler();

    return (
        <AuthContext.Provider
            value={{
                auth,
                setAuthStatus,
                setUnauthStatus,
                tableInputValues,
                setGlobalValues,
                dropdownConfigValues,
                setGlobalDropdownValues,
                checkupFilterValues,
                setGlobalCheckupFilterValues,
                fpClearFiles,
                setForecastPlatformClearFiles,
                fpCompData,
                setFPCompData,
                fpErrMsg,
                setFPErrMsg,
                fpForecastDsbl,
                setFPForecastDsbl,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
